import React from 'react'
import { Box, Link, Container, Grid } from '@material-ui/core'
import Text from './Text'
import logoSvg from '../images/logo.svg'

const Footer = ({ bgcolor, logo, linkStyle }) => {
    return (
        <Box component="footer" mt={0} bgcolor={bgcolor}>
            <Container>
                <Box py={4}>
                    <Grid container spacing={3} justify="space-between">
                        <Grid item xs={12} sm="auto">
                            <Box>
                                <img src={logo} />
                                <Text variant="body1" color="textSecondary">equity crowdfunding stock platform</Text>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm="auto">
                            <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                <Text variant="body1" color="textSecondary">
                                    Incubit Capital Club SAS<br />
                                        33, rue de la République, Allée B<br />
                                        69002 LYON, France<br />
                                    <Link href="mailto:contact@incubit.fr" style={linkStyle}>contact@incubit.fr</Link>
                                </Text>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

Footer.defaultProps = {
    bgcolor: "rgba(0,0,0,.87)",
    logo: logoSvg,
    linkStyle: {}
}

export default Footer
