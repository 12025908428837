/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'
import { Box, CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../theme'
import Footer from './Footer'
import '../utils/font-awesome'

const scrollToSection = section => e => {
  e.preventDefault()
  const getTarget = () => {
    if (document.getElementById(section)){
      return ReactDOM.findDOMNode(document.getElementById(section))
    }
  }
  
  const target = getTarget()

  if (!target) return

  const sectionTop = target.getBoundingClientRect().y + window.scrollY

  window.scrollTo({
    top: sectionTop,
    behavior: 'smooth'
  })

  window.history.pushState(null, null, `#${section}`)
}

export const HeaderHeightContext = React.createContext()
export const ScrollToSectionContext = React.createContext()

const Layout = ({ children, footerProps }) => {
  const headerRef = React.useRef()
  const [headerHeight, setHeaderHeight] = React.useState(0)

  React.useLayoutEffect(() => {
    if (headerRef.current.clientHeight !== headerHeight) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  })

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <HeaderHeightContext.Provider value={headerHeight}>
            <ScrollToSectionContext.Provider value={scrollToSection}>
              <Box display="flex" flexDirection="column" position="relative">
                <CssBaseline />

                <Header ref={headerRef} siteTitle={data.site.siteMetadata.title} />

                <Box component="main" display="flex" flexDirection="column" flexGrow={1}>
                  {children}
                </Box>

                <Footer {...footerProps} />
              </Box>
            </ScrollToSectionContext.Provider>
          </HeaderHeightContext.Provider>
        </ThemeProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
