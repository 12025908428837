import React from 'react'
import { Button, Box, useTheme, Container, makeStyles, Fade, Typography } from '@material-ui/core'
import ownerLogo from '../images/owner-logo.svg'
import investorLogo from '../images/investor-logo.svg'
import { ScrollToSectionContext } from './Layout'
import { useLocation, Link as RouterLink } from "@reach/router"
import Text from '../components/Text'

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.only('xs')]: {
    logo: {
      maxWidth: 120
    }
  }
}))

const Header = React.forwardRef((props, ref) => {
  const theme = useTheme()
  const classes = useStyles()
  const location = useLocation();

  //const isConfirmPage = location.pathname === '/confirm/'
  const isOwnerPage = location.pathname === '/owner' || location.pathname === '/'

  const scrollToSection = React.useContext(ScrollToSectionContext)

  return (
    <Box
      ref={ref}
      py={4}
      display="flex"
      position="absolute"
      top={0}
      width="100%"
      zIndex={theme.zIndex.appBar}
    >
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <img src={isOwnerPage ? ownerLogo : investorLogo} className={classes.logo} />

          <Fade in={true} key={location.pathname}>
            {
              !isOwnerPage ? <Box display="flex" alignItems="center">
                <Text mr={2} variant="h4" component="span" fontWeight={400}>comment investir pour aider</Text>
                <Button variant="contained" color="primary" component={RouterLink} to="/">
                  INVESTIR
                </Button>
              </Box> : <Box display="flex" alignItems="center">
                  <Text mr={2} variant="h4" component="span" fontWeight={400}>comment lever des fonds pour restart</Text>
                  <Button variant="contained" component={RouterLink} to="/investor">
                    DEMANDER
                  </Button>
                </Box>
            }
          </Fade>
        </Box>
      </Container>
    </Box>
  )
})

export default Header
