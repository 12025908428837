import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const PRIMARY_GRADIENT = "linear-gradient(to right, #AC9879, #816E52)"

export const INVESTOR_COLOR = '#F2C94C'
export const INVESTOR_COLOR_SECONDARY = '#EB5757'

const _theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 985, // changed
            lg: 1280,
            xl: 1920
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: `#AC9879`,
            dark: `#816E52`,
            contrastText: "#fff",
            mainGradient: PRIMARY_GRADIENT,
        },
        secondary: {
            main: '#3B5990',
            dark: '#1E2E4B',
            contrastText: "#fff",
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.9em',
            fontWeight: 700
        },
        h2: {
            fontSize: '2.15rem',
            fontWeight: 700
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 600
        },
        subtitle1: {
            fontWeight: 300
        }
    },
    overrides: {
        MuiFormLabel: {
            root: {
                '&.Mui-focused': {
                    color: `rgba(255, 255, 255, 0.7)`
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, .25)'
            },
            notchedOutline: {
                borderWidth: 2,
                '.MuiOutlinedInput-root.Mui-focused &': {
                    borderColor: `rgba(255, 255, 255, 0.7)`
                }
            },

        },
        MuiButton: {
            root: {
                letterSpacing: 0.75,
                paddingLeft: 16,
                paddingRight: 16
            },
            contained: {
                boxShadow: "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)" // 12 elevation
            },
            containedPrimary: {
                background: PRIMARY_GRADIENT
            }
        },
    },
    shape: {
        borderRadius: 0
    }
})

const theme = responsiveFontSizes(_theme);

theme.typography.subtitle1[theme.breakpoints.down('sm')] = {
    fontSize: '.9rem'
}

export default theme